import React from 'react';
import HeaderPicture from '../components/HeaderPicture';
import BlogTitle from '../components/BlogTitle';
import BlogBlock from '../components/BlogBlock';
import BlogImageBlock from '../components/BlogImageBlock';

const Blog01 = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
    <HeaderPicture src='https://images.unsplash.com/photo-1508440767412-59ce0b206bbc?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=4800' />
    <BlogTitle>02 - De manejo del fracaso</BlogTitle>
    <BlogBlock title='Del Fracaso'>
      Este es un tema que va a poner incómodos a más de uno, por que es un tema relativamente tabú en nuestra sociedad.
      El fracaso es tratado como, eso que jamas debe de pasar, y su rechazo es tan fuerte que la mayoría de las personas
      prefiere nunca empezar nada o incluso hacer algo nuevo por miedo a este mismo.<br />
      <br />
      Nuestra sociedad nos ha enseñado que fracasar es malo, que uno no puede ser un fracasado, pero al mismo tiempo nos
      detienen de tomar riesgos, por que no tiene caso hacer más.<br />
      <br />
      Esto nos lleva vivir en miedo, fracasado?! jamás!, uno podrá ser un borracho, mujeriego y huevon, pero nunca
      fracasado. Y aquí es cuando nos hacemos la pregunta de: Qué es un fracasado?. Tal vez una persona que intento algo
      y fallo, tal vez una persona que nunca intentó nada, tal vez una persona que ha fallado varias veces seguidas, o
      tal vez todos los anteriores.<br />
      <br />
      Desde un punto de vista personal yo creo que el fracaso es la acción de mantenernos estáticos, de simplemente
      esperar a que todo salga bien sin hacer nada. Este es un fenómeno cultural muy fuerte en los latinos, desde gente
      compartiendo imágenes con textos como: "Comparte esta imagen y mañana te vas a encontrar dinero", hasta otras
      diciendo; "Solo quiero un trabajo donde me paguen mucho y no haga nada". Todos hemos visto gente compartiendo eso.
      Lo curioso es que ese mismo tipo de personas nunca piensan "Para qué voy a querer encontrar dinero si puedo
      trabajar y ganárselo?" O simplemente planificar sus metas y buscar obtener ese dinero. Esto es por que es más
      fácil desear que arriesgarse a fracasar.
      <br />
      <br />Nacemos desnudos, vulnerables y confundidos, no sabemos lo que pasa a nuestro alrededor, todo es brillante,
      molesto, complicado, nuestros primeros segundos de vida no son más que un reflejo de lo que podría ser nuestro
      futuro, y desde que tenemos uso de razón peleamos en contra de todo eso, para mantener un control, para
      mantenernos seguros y no volver al caos del que provenimos, pero sin caos jamás habría cambios.
    </BlogBlock>
    <BlogBlock title='De ser estoico'>
      Bueno, esta palabrita no está nada fácil de decir, pero igual lo voy a intentar. Seguramente se preguntan qué es
      ser estoico, o que es siquiera el término estoicismo, suena raro, medio a exorcismo.<br />
      <br />
      El estoicismo es una doctrina filosófica donde se practica el dominio de las pasiones que perturba la vida,
      guiándonos en base a la virtud y la razón, con el objetivo de lograr la felicidad y la sabiduría.<br />
      <br />
      En un mundo donde cada vemos más ansiedad, caos, protestas, policías asesinando personas inocentes todos los días,
      políticos promoviendo violencia o ignorando por completo protocolos de salud, polarización en opiniones y
      política, este es el momento en el que más necesitamos aprender sobre esto, para mantener algo de control en
      nuestras vidas, para mantener algo de sanidad.<br />
      <br />
      De acuerdo a esta filosofía hay dos partes principales en nuestras vidas, las acciones externas y las acciones
      internas.<br />
      <br />
      Las acciones externas, fuera de nuestra mente, son cosas que no podemos controlar y por lo mismo carece de sentido
      sobre preocuparnos por ellas, así como no tenemos control sobre lo que las demás personas piensen de nosotros, lo
      que digan de nosotros, o las posibilidades socio-económicas en las que nos tocó nacer, la verdad es que fueron
      inevitables y no tuvimos nada que ver, no vale la pena perder tiempo en eso.<br />
      <br />
      Las acciones internas son nuestras interpretaciones y reacciones a esas acciones externas, y por lo tanto, las
      podemos controlar de la manera que nosotros deseamos.<br />
      <br />
      No podemos controlar si otros, por ejemplo, dicen que somos malas personas, pero sí podemos controlar que hacemos
      de nuestras vida para no serlo, desde ayudar a otras personas, guiarlas hasta adoptar a un perro callejero. Este
      enfoque nos ayuda a mantener la sanidad mental en situaciones fuera de nuestro control, y de alguna manera a crear
      una paz mental al saber que lo que hacemos está dentro de las posibilidades.<br />
      <br />
      Uno de los filósofos estoicos más importantes es Séneca, de este gran hombre proviene una gran cantidad de
      sabiduría. Séneca dijo: "El hombre sabio no es levantado por la prosperidad ni derribado por la adversidad; porque
      siempre se ha esforzado por confiar predominantemente en sí mismo y obtener toda la alegría de sí mismo". Esto lo
      podemos ver como una máxima, dado a que el fracaso siempre va a ser una parte latente de nuestras vidas, y dado a
      que la vida es difícil, nuestra felicidad solo debe de depender de nosotros y de cómo vemos nuestro mundo.<br />
      <br />
      En medio de una pandemia y de la recesión económica más contundente desde la gran depresión, tiene sentido ver las
      cosas de esta manera, es ahora cuando debemos de encontrar el tiempo y la forma de ser felices, por que si podemos
      lograrlo en las situaciones más difíciles, lograrlo cuando todo está bien será más sencillo.
    </BlogBlock>
    <BlogImageBlock
      title='De compartir nuestros fracasos'
      picture='https://images.unsplash.com/photo-1477414348463-c0eb7f1359b6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80'>
      Tenemos que cambiar la mentalidad de nuestra gente, de nuestra sociedad, de nuestras familias, tenemos que empujar
      la idea de que fracasar no es algo malo, y que el conocimiento generado por la experiencia de hacer las cosas mal
      suma de manera positiva.<br />
      <br />
      En el libro "The Business of Expertise" de David C Baker menciona que despues de hacer una investigación sobre que
      cualidades son las que crean a un emprendedor, a simple vista parecía que no había ningún patrón, había personas
      desde introvertidas, extrovertidas, todos los colores, nacionalidades, culturas, le pareció extremadamente
      extraño, así que siguió analizando los datos hasta que encontró el patrón, este era que las personas que emprenden
      toman riesgos, de hecho la mayoría de las veces están mal, y van a fracasar hasta el cansancio, pero cuando llegan
      a estar bien, esa sola vez que lo logran, vale por todas las que no.
    </BlogImageBlock>
    <BlogBlock>
      Sobre fracasos yo tengo mi propia lista interminable, recuerdo que cuando recién empece a buscar clientes
      internacionales, muchas veces no pasaba ni del primer mensaje, rechazado por completo, pero recuerdo claramente
      algo que me dijo el buen Eduardo Eurias, un activo representante de la comunidad tecnológica de México: "No
      necesitas que todas las empresas te digan que si, necesitas una sola buena empresa que te diga que si, es todo".
      Esas palabras tenían tanto sentido y al mismo tiempo me impresione como pude haber dejado pasar algo tan lógico,
      perdiéndome en el horizonte en vez de mirar lo que tenía enfrente de mi.
    </BlogBlock>
  </div>
);

export default Blog01;

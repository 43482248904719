import React from 'react';
import styled from 'styled-components';

const BlogImageBlock = ({
  title,
  children,
  picture = 'https://images.unsplash.com/photo-1519242220831-09410926fbff?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb'
}) => (
  <StyledContainer>
    <RowContainer>
      {title ? <h2 style={{ color: 'white', letterSpacing: '0.6rem' }}>{title}</h2> : null}
      <p style={{ color: 'white', letterSpacing: '0.2rem' }}>{children}</p>
    </RowContainer>
    <RowContainer>
      <img src={picture} style={{ maxWidth: '100%' }} alt='an idea' />
      <p style={{ color: 'white', letterSpacing: '0.2rem' }}>
        Sentirse perdido después de algunos años haciendo lo mismo es un sentimiento común que todos tenemos en algún
        momento.
      </p>
    </RowContainer>
  </StyledContainer>
);

const StyledContainer = styled.div`
  marginTop: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const RowContainer = styled.div`
  display: flex;
  // flex: 1;
  width: 48%;
  flex-direction: column;
  @media (max-width: 768px) {
    flex: 1;
    width: 100%;
  }
`;

export default BlogImageBlock;

import React from 'react';
import styled from 'styled-components';
import COLORS from 'constants/colors';

const BlogTitle = ({ children }) => (
  <StyledContainer>
    <h1>{children}</h1>
    <span>Escrito por: Oscar Gallo</span>
  </StyledContainer>
);

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  h1 {
    color: white;
    letter-spacing: 1.2rem;
    text-align: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  span {
    letter-spacing: 0.8rem;
    text-align: start;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 900;
    color: ${COLORS.text};
    background-color: ${COLORS.lightDarkBackground};
  }
`;

export default BlogTitle;

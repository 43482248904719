import React from 'react';
import BlogItem from './components/BlogItem';
// Styles
import styles from './styles';

const AboutMeScreen = () => {
  return (
    <div style={styles.masterDiv}>
      <span style={styles.title}>Blog</span>
      <div style={styles.container} className='container-home'>
        {blogItemsData.map((card) => <BlogItem key={card.id} {...card} />)}
      </div>
    </div>
  );
};

const blogItemsData = [
  // Photo by ivan Torres on Unsplash
  {
    id                : '01',
    category          : 'Pizza',
    title             : '01 - Saliendo de la zona de confort',
    backgroundPicture :
      'https://images.unsplash.com/photo-1511256094521-c1cf19529095?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=4800'
  },
  // Photo by Dennis Brendel on Unsplash
  {
    id                : '02',
    category          : 'How to',
    title             : '02 - De manejo del fracaso',
    backgroundPicture :
      'https://images.unsplash.com/photo-1508440767412-59ce0b206bbc?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=4800'
  }
];

export default AboutMeScreen;

import COLORS from 'constants/colors';

const styles = {
  header   : {
    width           : '100%',
    position        : 'fixed',
    top             : 0,
    backgroundColor : COLORS.background,
    height          : 80,
    boxShadow       : '0px 0px 5px rgba(0, 0, 0, 0.25)',
    display         : 'flex',
    alignItems      : 'center',
    zIndex          : 900
  },
  logo     : {
    // position : 'absolute',
    height : 45,
    width  : 45
    // top      : 30,
    // left     : 10
  },
  title    : {
    color         : COLORS.text,
    textAlign     : 'center',
    fontWeight    : 'bold',
    letterSpacing : '0.4rem',
    textTransform : 'uppercase'
  },
  ul       : {
    height         : 45,
    width          : '100%',
    display        : 'flex',
    justifyContent : 'space-around',
    alignItems     : 'center',
    listStyle      : 'none'
  },
  li       : {
    textAlign : 'center',
    height    : 45,
    margin    : 0,
    width     : '40%'
  },
  menuLine : {
    fill : COLORS.text
  },
  nav      : {
    display         : 'flex',
    position        : 'absolute',
    minWidth        : 0,
    flexBasis       : 400,
    flexDirection   : 'column',
    backgroundColor : COLORS.text,
    padding         : '2rem',
    transform       : 'translateX(200%)',
    right           : 0,
    top             : 150,
    width           : 220,
    boxShadow       : '5px 5px 5px rgba(0, 0, 0, 0.35)'
  },
  navUl    : {
    display       : 'flex',
    flexDirection : 'column',
    alignItems    : 'flex-start',
    margin        : 0,
    padding       : 0,

    listStyle     : 'none'
  },
  navLi    : {
    marginTop    : 10,
    marginBottom : 10
  },
  navLink  : {
    color          : COLORS.background,
    textDecoration : 'none',
    fontWeight     : '700',
    fontSize       : 30,
    letterSpacing  : '1.2rem'
  },
  icon     : {
    color : COLORS.lightDarkBackground
  }
};

export default styles;

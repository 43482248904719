import React from 'react';
// Styling
import styles from './style';
import styled from 'styled-components';
// import './styles.css';
import COLORS from 'constants/colors';
import PodcastItem from './components/PodcastItem';

const HomeScreen = () => {
  return (
    <div style={styles.masterDiv}>
      <span style={styles.title}>Podcast</span>
      <StyledContainer className='container-home'>
        {EpisodesData.reverse().map((item) => <PodcastItem key={item.title} {...item} />)}
      </StyledContainer>
    </div>
  );
};

const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-Direction: column;
  background-color: ${COLORS.lightDarkBackground};
  width: 90%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 20px;
  @media (max-width: 1200px) {
    width: 70%;
  }
  @media (max-width: 2200px) {
    width: 70%;
  }
`;

const EpisodesData = [
  {
    title   : '01 - Salir de la zona de confort',
    apple   :
      'https://podcasts.apple.com/us/podcast/omam-01-saliendo-de-nuestra-zona-de-confort/id1517114944?i=1000476928665',
    spotify : 'https://open.spotify.com/episode/3gRlsctfE9cadIs5yaCYeX?si=niyELQ_LRempNMLUHWsj8g',
    google  : 'https://www.google.com/podcasts?feed=aHR0cHM6Ly9hbmNob3IuZm0vcy8yNDg3ODhmOC9wb2RjYXN0L3Jzcw==',
    anchor  : 'https://anchor.fm/oscar-gallog/episodes/OMAM-01---Saliendo-de-nuestra-zona-de-Confort-eeutp4'
  },
  {
    title   : '02 - De como manejar el fracaso',
    apple   : 'https://podcasts.apple.com/us/podcast/omam-02-de-manejo-del-fracaso/id1517114944?i=1000477364034',
    spotify : 'https://open.spotify.com/episode/0cWL66HvHvp7u78ilFVk57?si=Md1J9NCQTZmK3h0GAYoMeA',
    google  : 'https://www.google.com/podcasts?feed=aHR0cHM6Ly9hbmNob3IuZm0vcy8yNDg3ODhmOC9wb2RjYXN0L3Jzcw==',
    anchor  : 'https://anchor.fm/oscar-gallog/episodes/OMAM-02---De-manejo-del-fracaso-ef78m7'
  },
  {
    title   : '03 - De el síndrome del impostor',
    apple   : 'https://podcasts.apple.com/us/podcast/03-de-el-s%C3%ADndrome-del-impostor/id1517114944?i=1000478398825',
    spotify : 'https://open.spotify.com/episode/52UI4mlzorj0tbn7wkb0Hi?si=8os5X6iaQneJzucMGFuJRA',
    google  : 'https://www.google.com/podcasts?feed=aHR0cHM6Ly9hbmNob3IuZm0vcy8yNDg3ODhmOC9wb2RjYXN0L3Jzcw==',
    anchor  : 'https://anchor.fm/oscar-gallog/episodes/OMAM-03---De-el-sndrome-del-impostor-efipfl'
  },
  {
    title   : '04 - De lo que vale la pena sacrificarse',
    apple   : 'https://podcasts.apple.com/us/podcast/of-man-and-machines/id1517114944?i=1000479472200',
    spotify : 'https://open.spotify.com/episode/3Taw2C5cZRmvPr9arcKTat?si=6eP0zpmPTqq3RA0KF0B1Yw',
    google  :
      'https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yNDg3ODhmOC9wb2RjYXN0L3Jzcw/episode/M2M4NDNiYjktZmM1OC00YTA2LWI0ODQtMzQ5MzAxYjAyNjIx?sa=X&ved=0CBEQzsICahcKEwjIlvvD4pHrAhUAAAAAHQAAAAAQBA',
    anchor  : 'https://anchor.fm/oscar-gallog/episodes/OMAM-04---De-lo-que-vale-la-pena-sacrificarse-efsghg'
  },
  {
    title   : '05 - De trabajar internacional',
    apple   : 'https://podcasts.apple.com/us/podcast/of-man-and-machines/id1517114944?i=1000481436452',
    spotify : 'https://open.spotify.com/episode/2fyBqEO9zWppvgqHKQuMfX?si=BQ1fCWfER_GcC8JFNtj30A',
    google  :
      'https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yNDg3ODhmOC9wb2RjYXN0L3Jzcw/episode/YTc4Y2M5MTAtM2Q1Yi00M2UxLThmMzMtMTdhMzIzNzE1NTYx?sa=X&ved=0CA8QzsICahcKEwjIlvvD4pHrAhUAAAAAHQAAAAAQBA',
    anchor  : 'https://anchor.fm/oscar-gallog/episodes/OMAM-05---De-trabajar-internacional-eg6crr'
  },
  {
    title   : '06 - De la salud mental',
    apple   : 'https://podcasts.apple.com/us/podcast/of-man-and-machines/id1517114944?i=1000485116133',
    spotify : 'https://open.spotify.com/episode/6reTgD8T0fHQPgt4ePv2gY?si=X17uGe-1SWyo-Zkis3M2Sw',
    google  :
      'https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yNDg3ODhmOC9wb2RjYXN0L3Jzcw/episode/NDI5ODU4ZmUtZWY0Yy00MmYzLWJjODktNDk3NGRkNmRmYWY2?sa=X&ved=0CA0QzsICahcKEwjIlvvD4pHrAhUAAAAAHQAAAAAQBA',
    anchor  : 'https://anchor.fm/oscar-gallog/episodes/OMAM-06---De-la-salud-mental-egpq9f'
  },
  {
    title   : '07 - De invertir en uno mismo',
    apple   : 'https://podcasts.apple.com/us/podcast/of-man-and-machines/id1517114944?i=1000485780017',
    spotify : 'https://open.spotify.com/episode/65nrIFnl2N2GsSdJS7J7Ar?si=I5utXPyeR3iPAMXhbsgoQg',
    google  :
      'https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yNDg3ODhmOC9wb2RjYXN0L3Jzcw/episode/ZWRiNmUyZjMtYTFmMi00MDcxLWE1ZWEtNmI2ZjZjODZmYzg5?sa=X&ved=0CAsQzsICahcKEwjIlvvD4pHrAhUAAAAAHQAAAAAQBA',
    anchor  : 'https://anchor.fm/oscar-gallog/episodes/OMAM-07---De-invertir-en-uno-mismo-eh42eg'
  },
  {
    title   : '08 - De negociación de salarios',
    apple   :
      'https://podcasts.apple.com/us/podcast/of-man-and-machines/id1517114944#episodeGuid=82bc0b8b-328b-4237-9d32-d1d07e6fd399',
    spotify : 'https://open.spotify.com/episode/24WAgT6XbkY5JvLfcZSXKN?si=spaX7T09TyueRmvRCoWqiw',
    google  :
      'https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yNDg3ODhmOC9wb2RjYXN0L3Jzcw/episode/ODJiYzBiOGItMzI4Yi00MjM3LTlkMzItZDFkMDdlNmZkMzk5?sa=X&ved=0CAkQ38oDahcKEwjIlvvD4pHrAhUAAAAAHQAAAAAQBA',
    anchor  : 'https://anchor.fm/oscar-gallog/episodes/OMAM-08---De-negociacin-de-salarios-ehnqvo'
  },
  {
    title   : '09 - De seguir tu propio camino',
    apple   : 'https://podcasts.apple.com/us/podcast/of-man-and-machines/id1517114944?i=1000487953431',
    spotify : 'https://open.spotify.com/episode/5F3SmqpFGVyYXRIR6ZiCFq?si=MhPIzHO0TB-adsiuQmM-Cg',
    google  :
      'https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yNDg3ODhmOC9wb2RjYXN0L3Jzcw/episode/ZmRkODU0ODMtNmE5Ny00ZjQ0LTk2NGItNWRmY2E3NTg5MGQ3?sa=X&ved=0CAUQkfYCahcKEwjAyNzzmpnsAhUAAAAAHQAAAAAQAQ',
    anchor  : 'https://anchor.fm/oscar-gallog/episodes/OMAM-09---De-seguir-tu-propio-camino-ei2hda'
  },
  {
    title   : '10 - De cómo trabajar de freelancer',
    apple   : 'https://podcasts.apple.com/us/podcast/of-man-and-machines/id1517114944?i=1000490962768',
    spotify : 'https://open.spotify.com/episode/1plNTeRIFu0mipEgHZRlG7?si=fh84VHrXT6WKWXrfq9Fz7g',
    google  :
      'https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yNDg3ODhmOC9wb2RjYXN0L3Jzcw/episode/ZWE3YWU4NjEtNDE3YS00NDQ5LWI5YmItMzc1NWE2ZDgwN2Rl?sa=X&ved=0CAUQkfYCahcKEwjAyNzzmpnsAhUAAAAAHQAAAAAQAQ',
    anchor  : 'https://anchor.fm/oscar-gallog/episodes/OMAM-10---De-cmo-trabajar-de-freelancer-ejg66e'
  },
  {
    title   : '11 - De crear nuestros hábitos',
    apple   : 'https://podcasts.apple.com/us/podcast/of-man-and-machines/id1517114944?i=1000493120603',
    spotify : 'https://open.spotify.com/episode/64lH4bG9odTckDJ5qwqp9m?si=2x1gqmO4TQCpo0UdDSaAmA',
    google  :
      'https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8yNDg3ODhmOC9wb2RjYXN0L3Jzcw/episode/ZjFiMjlkOTItYmRmNC00ZjUwLTg2NTItN2NmMDVhYjY4MGRk?sa=X&ved=0CAUQkfYCahcKEwjAyNzzmpnsAhUAAAAAHQAAAAAQAQ',
    anchor  : 'https://anchor.fm/oscar-gallog/episodes/OMAM-11---De-crear-nuestros-hbitos-ekdc3vs'
  }
];

export default HomeScreen;

import React from 'react';
import styled from 'styled-components';
import COLORS from 'constants/colors';
import ME from 'assets/images/me.jpg';

const AboutMeScreen = () => (
  <Container>
    <span>Acerca de mi</span>
    <StyledContainer>
      <RowContainer>
        <img src={ME} style={{ width: '100%' }} alt='Oscar Gallo&#39;s' />
      </RowContainer>
      <RowContainer>
        <h2>Hello There!</h2>
        <p>Mi nombre es Oscar Gallo.</p>
        <p>
          Ayudo a empresas internacionales a crear hermosas aplicaciones web y móviles, con las últimas tecnologías.
        </p>
        <p>También soy conferencista, mentor, podcaster y digital nomad. Viajo por todo el mundo mientras trabajo</p>
        <p>
          A mis 34 años pase de un ingreso de $276 dólares mensuales a $8000 dólares mensuales, un incremento de casi 29
          veces más.
        </p>
        <p>
          Actualmente sigo creando software y compartiendo lo que se a travez de mi Podcast, desde crecimiento
          profesional, negociación de sueldos, entre otros temas.
        </p>
      </RowContainer>
    </StyledContainer>
  </Container>
);

const Container = styled.div`c
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    color: ${COLORS.text};
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
    font-size: 48px;
    letter-spacing: 0.6rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 40px;
  }
`;

const StyledContainer = styled.div`
  marginTop: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`;
const RowContainer = styled.div`
  display: flex;
  // flex: 1;
  width: 48%;
  flex-direction: column;
  h2 {
    color: white;
    letter-spacing: 0.6rem;
  }
  p {
    color: white;
    letter-spacing: 0.2rem;
  }
  @media (max-width: 768px) {
    flex: 1;
    width: 100%;
  }
`;

export default AboutMeScreen;

import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import {FaInstagram} from "react-icons/fa"
import styles from './styles';
import COLORS from 'constants/colors';

const variants = {
  open   : { opacity: 1, x: 0 },
  closed : { opacity: 0, x: '200%' }
};

const Header = () => {
  const [
    open,
    setOpen
  ] = useState(false);
  const ref = useRef(null);

  function OutsideAlerter(ref) {
    useEffect(
      () => {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setOpen(false);
          }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      },
      [
        ref
      ]
    );
  }
  OutsideAlerter(ref);
  return (
    <header style={styles.header} ref={ref}>
      <ul style={styles.ul}>
        <li style={{ ...styles.li, width: '50%', display: 'flex', alignItems: 'center' }}>
          <NavLink to="/" style={{textDecoration: 'none'}} exact>
            <span style={styles.title}>Of man and Machines Podcast</span>
          </NavLink>
        </li>
        <li style={{ ...styles.li, textAlign: 'right', zIndex: 99999 }} onClick={() => setOpen(!open)}>
          <svg viewBox='0 0 100 80' width='40' height='40'>
            <rect width='100' height='15' rx='8' style={styles.menuLine} />
            <rect y='30' width='80' height='15' rx='8' style={styles.menuLine} />
            <rect y='60' width='100' height='15' rx='8' style={styles.menuLine} />
          </svg>
        </li>
      </ul>
      <motion.nav style={styles.nav} animate={open ? 'open' : 'closed'} variants={variants}>
        <ul style={styles.navUl}>
          <li style={styles.navLi}>
            <NavLink
              to='/'
              exact
              style={styles.navLink}
              activeStyle={{
                borderBottomColor : COLORS.lightDarkBackground,
                borderBottomWidth : 5,
                borderBottomStyle : 'solid'
              }}>
              Podcast
            </NavLink>
          </li>
          <li style={styles.navLi}>
            <NavLink
              to='/blog'
              style={styles.navLink}
              activeStyle={{
                borderBottomColor : COLORS.lightDarkBackground,
                borderBottomWidth : 5,
                borderBottomStyle : 'solid'
              }}>
              Blog
            </NavLink>
          </li>
          <li style={styles.navLi}>
            <NavLink
              to='/about-me'
              style={styles.navLink}
              activeStyle={{
                borderBottomColor : COLORS.lightDarkBackground,
                borderBottomWidth : 5,
                borderBottomStyle : 'solid'
              }}>
              Acerca de mi
            </NavLink>
          </li>
          <li style={styles.navLi}>
            <a style={styles.navLink} alt="instagram" href="https://www.instagram.com/omam_podcast/" target="_blank" rel="noopener noreferrer">
              <FaInstagram size={40} style={styles.icon} />
            </a>
          </li>
        </ul>
      </motion.nav>
    </header>
  );
};

export default Header;

export const redPalette = {
  accent              : '#c03546',
  background          : '#f26d5b',
  text                : '#f6ea8c',
  lightDarkBackground : '#492540'
};

export const pinkPalette = {
  accent              : '#FF9B8B',
  background          : '#FDD7D2',
  text                : '#4E295B',
  lightDarkBackground : '#CC837E'
};

export const darkLightBluePalette = {
  accent              : '#3d5af1',
  background          : '#22d1ee',
  text                : '#e2f3f5',
  lightDarkBackground : '#0e153a'
};

export const darkBluePalette = {
  accent              : '#4A5590',
  background          : '#226089',
  text                : 'white',
  lightDarkBackground : 'rgba(0, 0, 0, 0.5)'
};

export default darkBluePalette;

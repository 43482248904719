import React from 'react';
import { Link } from 'react-router-dom';
import COLORS from 'constants/colors';

const BlogItem = ({ id, title, backgroundPicture }) => (
  <Link style={styles.container} to={`/blog/${id}`}>
    <div
      style={{
        ...styles.container,
        background : `url(${backgroundPicture}) no-repeat center center fixed`,
        backgroundSize: 'cover',
        width           : '100%',
        margin          : 0,
        padding         : 0
      }}>
      <h1 style={styles.title}>{title}</h1>
    </div>
  </Link>
);

const styles = {
  container : {
    width           : '90%',
    backgroundColor : COLORS.text,
    marginTop       : 20,
    marginBottom    : 20,
    borderRadius    : 20,
    minHeight       : 320,
    backgroundSize  : 'cover',
    display         : 'flex',
    justifyContent  : 'center',
    alignItems      : 'center',
    textDecoration  : 'none'
  },
  title     : {
    color         : 'white',
    fontWeight    : '700',
    letterSpacing : '1rem',
    textShadow    : '2px 2px rgba(0, 0, 0, 0.25)',
    textAlign     : 'center',
    lineHeight    : '2.5rem',
    width         : '80%'
  }
};

export default BlogItem;

import React from 'react';
import HeaderPicture from '../components/HeaderPicture';
import BlogTitle from '../components/BlogTitle';
import BlogBlock from '../components/BlogBlock';
import BlogImageBlock from '../components/BlogImageBlock';

const Blog01 = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
    <HeaderPicture src='https://images.unsplash.com/photo-1511256094521-c1cf19529095?ixlib=rb-1.2.1&amp;q=85&amp;fm=jpg&amp;crop=entropy&amp;cs=srgb' />
    <BlogTitle>01 - Saliendo de nuestra zona de confort</BlogTitle>
    <BlogBlock title='La zona de confort'>
      La zona de confort podemos definirla como un estado casi natural donde nos sentimos cómodos con nosotros mismos.
      Usualmente este estado es el resultado de un balance, este balance proviene de un bajo nivel de riesgos pero al
      mismo tiempo un bajo nivel de retribución. <br />
      <br />
      La zona de confort nos hace sentir bien hasta que tenemos un problema lo suficientemente grande para darnos cuenta
      que por culpa de esa comodidad que escogimos, sacrificamos mucho de nuestro crecimiento, tanto personal, como
      económico, como de salud.<br />
      <br />
      Este estado es usualmente reforzado por la sociedad y la gente a nuestro alrededor, jugar la vida en modo fácil
      nos hace pensar que pisamos en tierra firme, y si eres nacido en Latinoamérica sabes que la vida ya es
      suficientemente difícil como para agregar más capas de dificultad a esto.<br />
      <br />
      Creo que la mayoría hemos llegado a un punto en el que sentimos que tuvimos la oportunidad de hacer más, pero no
      lo hicimos, que tuvimos la oportunidad de mejorar y probar cosas diferentes, pero no lo hicimos, un sentimiento de
      estar perdidos, de que en algún punto desapareció el rumbo y dejamos de seguir lo que realmente deseábamos y
      terminamos haciendo lo que los demás esperaban de nosotros.
    </BlogBlock>
    <BlogImageBlock title='Como la zona de confort nos frena'>
      El miedo nos hace perder oportunidades, y muchas de ellas no se vuelven a repetir. Es cierto que las oportunidades
      vienen y van a lo largo de nuestra vida, pero si lo único que hacemos es verlas pasar, cual trafico en una tarde
      ocupada, nunca lograremos algo, seremos unos simples espectadores cuando podríamos ser parte del acto.<br />
      <br />
      Mantenernos estáticos nos va a frenar en los 3 puntos más importantes de nuestra vida: crecer profesionalmente,
      crecer como personas y por último nuestra salud.<br />
      <br />
      Si alguna vez analizaste, tal vez con mucha envidia, por qué una persona en tu trabajo súbitamente tiene un mejor
      puesto que tú o un mejor sueldo que tu?. Por un minuto piensa con la cabeza bien fría, deja de lado todo
      resentimiento o pensamiento de que fue por que el estuvo lamiendo las botas al jefe y tu no y considera esto:<br
      />
      <br />
      - Qué hizo el que tu no hiciste?<br />
      - Qué hizo esa persona mejor que tú?<br />
      - Hay algo que puedas aprender de esa persona?
    </BlogImageBlock>
    <BlogBlock title='Saliendo de la zona de confort'>
      Es difícil intentar algo nuevo, hay un miedo implícito en cambiar las cosas, el miedo es tan fuerte que incluso
      las personas pelean para mantener las cosas iguales, podemos ver esto en todo el mundo, desde movimientos
      políticos para mantener el status quo, hasta movimientos sociales para frenar los avances, el miedo no solo nos
      detiene personalmente de lograr nuestras metas en la vida, pero también nos detiene como sociedad.<br />
      <br />
      Este miedo es un reflejo de nuestra propia fragilidad, cuando intentamos hacer algo nuevo estamos al acecho de
      otras personas mejores que nosotros en el campo que intentemos, nos paraliza la idea de compararnos con ellos,
      pero sobre todo, nos da pánico el pensar que podemos quedar en ridículo.<br />
      <br />
      En esos momentos en que dudamos, que queremos detenernos y volver a la normalidad es cuando más debemos de luchar.
      Debemos de entender que compararnos con otra persona carece de sentido, esa persona puede tener 20 años haciendo
      eso que tu justo acabas de comenzar, qué sentido tendría hacer esto?. Es como querer ser corredor olímpico en tu
      primera semana de ir a hacer ejercicio.<br />
      <br />
      Necesitamos metas viables, sostenibles, pero sobre todo, la única comparación importante para nosotros es con
      nosotros mismos. No te compares con otras personas, podemos compararnos con nuestra versión de ayer, de hace un
      mes, de hace un año, compara tu progreso y evolución, admirar tu progreso. Un metro caminado en una carrera de mil
      metros siempre es una buena meta.
    </BlogBlock>
    <BlogBlock title='Área de crecmiento'>
      Una vez que hemos sobrepasado la etapa del miedo todo comienza a verse más claro en nuestro camino, súbitamente
      nos damos cuenta no solo de todas las cosas que hicimos mal, si no de todas las cosas que podemos hacer bien.<br />
      <br />
      Nos sentimos más cómodos haciendo actividades diferentes y aprendemos más que nunca, desde aprender a tocar un
      instrumento, hasta leer más, hacer ejercicio o aprender cualquier habilidad nueva como programar, electrónica,
      cocinar o hacer muebles de madera.<br />
      <br />
      De manera personal tengo una meta semanal: aprender algo nuevo por al menos 2 horas cada 2 o 3 días, para acumular
      un mínimo de 6 horas semanales de conocimiento nuevo. Si trabajas haciendo lo que te gusta, como es mi caso, esta
      es una oportunidad perfecta para crecer y mejorar, y siempre estar un paso delante de los que prefieren no
      estudiar.
    </BlogBlock>
    <BlogImageBlock picture='https://images.unsplash.com/photo-1502230831726-fe5549140034?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb'>
      Leer es otra de esas actividades que te hacen crecer muchísimo, leer solo 30 minutos por día, casi la mitad de un
      episodio de una serie en Netflix, te puede llevar a terminar 33 libros por año. Si solo lees 15 minutos al día,
      esos 15 minutos que usas para ver Instagram o Twitter, aunque aceptémoslo, de seguro es más que 15 minutos
      diarios, te llevarían a leer entre 13 y 16 libros al año, 16 veces más sabio que el año anterior.<br />
      <br />
      Si bien es cierto que cada quien escoge su propio veneno, y en mi caso particular he relegado la importancia de mi
      salud por obtener más crecimiento profesional, es algo que estoy cambiando, y ese es el punto de esto, empujar
      cambios en nosotros mismos, sin tiranizar nos, darnos cuenta que hay veces que vamos a fallar, pero eso es solo
      parte del proceso de crecimiento.
    </BlogImageBlock>
    <BlogBlock>
      En "Atomic Habits" de James Clear habla sobre las estrategias que podemos usar para crear hábitos nuevos en
      nuestra vida, la táctica es siempre empezar com poco, con 10 minutos un día a la semana haciendo algo nuevo, y
      luego incrementa poco a poco gradualmente, de 10 minutos nos vamos a 15 minutos la segunda semana, luego a 20
      minutos, eventualmente podemos incrementar los días que lo hacemos hasta que obtenemos nuestro nuevo hábito.<br />
      <br />
      No hay que perder de vista que esto es un viaje no una carrera, no importa el número de veces que fallemos,
      mientras continuemos intentándolo siempre habrá una manera de lograr nuestras metas, cómo encaramos los fallos
      habla de nuestra madurez emocional y nuestra capacidad de movernos adelante.
    </BlogBlock>
  </div>
);

export default Blog01;

import React from 'react';
import styled from 'styled-components';
import COLORS from 'constants/colors';
// Logos
import { ReactComponent as SpotifyLogo } from 'assets/podcast_logos/spotify.svg';
import { ReactComponent as GoogleLogo } from 'assets/podcast_logos/google.svg';
import { ReactComponent as AppleLogo } from 'assets/podcast_logos/apple.svg';
import AnchorLogo from 'assets/podcast_logos/anchor.png';

const PodcastItem = ({ title = 'Item 1', spotify = '', google = '', anchor = '', apple = '' }) => (
  <StyledContainer>
    <span className='title'>{title}</span>
    <span className='subtitle'>Escuchar en:</span>
    <ul style={styles.ul}>
      <a href={spotify} alt='spotify' target='_blank' rel='noopener noreferrer'>
        <li style={styles.li}>
          <SpotifyLogo style={styles.logo} />
        </li>
      </a>
      <a href={apple} alt='spotify' target='_blank' rel='noopener noreferrer'>
        <li style={styles.li}>
          <AppleLogo style={styles.logo} />
        </li>
      </a>
      <a href={google} alt='spotify' target='_blank' rel='noopener noreferrer'>
        <li style={styles.li}>
          <GoogleLogo style={styles.logo} />
        </li>
      </a>
      <a href={anchor} alt='spotify' target='_blank' rel='noopener noreferrer'>
        <li style={styles.li}>
          <img src={AnchorLogo} style={styles.logo} alt='Anchor logo' />
        </li>
      </a>
    </ul>
  </StyledContainer>
);

const StyledContainer = styled.div`
  background-color: ${COLORS.accent};
  min-height: 260px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 30px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  .title {
    color: ${COLORS.text};
    font-weight: 700;
    font-size: 1.3rem;
    text-align: center;
    width: 80%;
    text-transform: uppercase;
    @media (min-width: 900px) {
      font-size: 1.8rem;
    }
    @media (min-width: 1024px) {
      font-size: 1.8rem;
    }
    @media (min-width: 1200px) {
      font-size: 1.8rem;
    }
  }
  .subtitle {
    color: ${COLORS.text};
    margin-top: 10px;
    margin-bottom: 10px;

    @media (min-width: 900px) {
      font-size: 1.8rem;
    }
    @media (min-width: 1024px) {
      font-size: 1.8rem;
    }
    @media (min-width: 1200px) {
      font-size: 1.8rem;
    }
  }
`;

// 320px.
// 480px.
// 600px.
// 768px.
// 900px.
// 1024px.
// 1200px.

const styles = {
  container : {
    backgroundColor : COLORS.accent,
    minHeight       : 260,
    marginTop       : 20,
    marginBottom    : 20,
    borderRadius    : 30,
    width           : '90%',
    display         : 'flex',
    justifyContent  : 'center',
    alignItems      : 'center',
    flexDirection   : 'column',
    padding         : 10
  },
  ul        : {
    display        : 'flex',
    flexDirection  : 'row',
    width          : '80%',
    justifyContent : 'space-around',
    alignItems     : 'center',
    padding        : 0,
    paddingTop     : 20,
    listStyle      : 'none',
    flexWrap       : 'wrap'
  },
  li        : {
    height         : 40,
    display        : 'flex',
    justifyContent : 'center',
    paddingTop     : 5,
    paddingBottom  : 5,
    paddingRight   : 10,
    paddingLeft    : 10
  },
  logo      : {
    height : 40,
    width  : 40
  },
  title     : {
    color         : COLORS.text,
    fontWeight    : '700',
    fontSize      : '1.3rem',
    textAlign     : 'center',
    width         : '80%',
    textTransform : 'uppercase'
  },
  subtitle  : {
    color        : COLORS.text,
    marginTop    : 10,
    marginBottom : 10
  }
};

export default PodcastItem;

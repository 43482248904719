import React from 'react';
import { useParams } from 'react-router-dom';
import COLORS from 'constants/colors';
import Blog01 from './files/01';
import Blog02 from './files/02';

const BlogData = [
  { id: '01', view: Blog01 },
  { id: '02', view: Blog02 }
];

const BlogDetails = () => {
  const { id } = useParams();
  const Blog = BlogData.find((blog) => blog.id === id);
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <span style={styles.title}>Blog Entry</span>
      <div style={{ maxWidth: '80%' }}>
        <Blog.view />
      </div>
    </div>
  );
};

const styles = {
  title : {
    color         : COLORS.text,
    textShadow    : '0px 2px 5px rgba(0, 0, 0, 0.25)',
    fontSize      : 48,
    letterSpacing : '0.6rem',
    fontWeight    : 700,
    textAlign     : 'center',
    textTransform : 'uppercase',
    marginBottom  : 40
  }
};

export default BlogDetails;

import React from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// Components & Styling
import Container from './components/container';
import Header from './components/header';
import COLORS from './constants/colors';
// Screens;
import HomeScreen from './views/home';
import BlogScreen from './views/blog';
import BlogDetails from 'views/blog_details';
import AboutMeScreen from './views/about_me';

function App() {
  return (
    <Router>
      <Container>
        <Header />
        <div style={styles.container}>
          {/* <span style={styles.title}>Podcast</span> */}
          <Switch>
            <Route path='/about-me'>
              <AboutMeScreen />
            </Route>
            <Route path='/blog/:id'>
              <BlogDetails />
            </Route>
            <Route path={'/blog'}>
              <BlogScreen />
            </Route>
            <Route path='/'>
              <HomeScreen />
            </Route>
          </Switch>
        </div>
      </Container>
    </Router>
  );
}

ReactGA.initialize('UA-000000-01');
ReactGA.pageview(window.location.pathname + window.location.search);

export default App;

const styles = {
  container : {
    display        : 'flex',
    justifyContent : 'flex-end',
    alignItems     : 'center',
    width          : '90%',
    flexDirection  : 'column',
    marginTop      : 120
  },
  title     : {
    color         : COLORS.text,
    textShadow    : '0px 2px 5px rgba(0, 0, 0, 0.25)',
    fontSize      : 48,
    letterSpacing : '0.6rem',
    fontWeight    : 700,
    textAlign     : 'center',
    textTransform : 'uppercase',
    marginBottom  : 40
  }
};

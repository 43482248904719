import React from 'react';
import styled from 'styled-components';

const BlogBlock = ({ title, children }) => (
  <StyledContainer>
    {title ? <h2>{title}</h2> : null}
    <p>{children}</p>
  </StyledContainer>
);

const StyledContainer = styled.div`
  margin-top: 10px;
  h2 {
    color: white;
    letter-spacing: 0.6rem;
  }
  p {
    color: white;
    letter-spacing: 0.2rem;
  }
`;

export default BlogBlock;

import React from 'react';
import COLORS from 'constants/colors';

const Container = ({ children }) => (
  <div
    style={{
      backgroundColor : COLORS.background,
      // minHeight       : '100vh',
      width           : '100vw',
      display         : 'flex',
      justifyContent  : 'center',
      alignItems      : 'center',
      flexDirection   : 'column',
      flex            : 1
    }}>
    {children}
  </div>
);

export default Container;

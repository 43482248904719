import COLORS from 'constants/colors';

export default {
  masterDiv : {
    width          : '100%',
    display        : 'flex',
    flexDirection  : 'column',
    justifyContent : 'center',
    alignItems     : 'center'
  },
  container : {
    display              : 'flex',
    justifyContent       : 'flex-end',
    alignItems           : 'center',
    flexDirection        : 'column',
    backgroundColor      : COLORS.lightDarkBackground,
    width                : '90%',
    borderTopLeftRadius  : 30,
    borderTopRightRadius : 30,
    padding              : 20
  },
  title     : {
    color         : COLORS.text,
    textShadow    : '0px 2px 5px rgba(0, 0, 0, 0.25)',
    fontSize      : 48,
    letterSpacing : '0.6rem',
    fontWeight    : 700,
    textAlign     : 'center',
    textTransform : 'uppercase',
    marginBottom  : 40
  }
};
